import AdoriService from '@/services/adori_v6'
import store from '@/store'
import { queryDefaults } from '@/utils/query.config'
import { useMutation, useQuery } from 'vue-query'

// Query Keys
export enum musicQueryKeys {
  LIB_MUSIC = 'LIB_MUSIC',
  SEARCH_MUSIC = 'SEARCH_MUSIC',
}

export enum genreEnum {
  ALTERNATIVE = 'Alternative & Punk',
  AMBIENT = 'Ambient',
  CHILDREN = "Children's",
  CINEMATIC = 'Cinematic',
  CLASSICAL = 'Classical',
  COUNTRY = 'Country & Folk',
  DANCE = 'Dance & Electronic',
  HIPHOP = 'Hip-Hop & Rap',
  HOLIDAY = 'Holiday',
  JAZZ = 'Jazz & Blues',
  POP = 'Pop',
  SOUL = 'R&B & Soul',
  REGGAE = 'Reggae',
  ROCK = 'Rock',
}

export enum moodEnum {
  ANGRY = 'Angry',
  BRIGHT = 'Bright',
  CALM = 'Calm',
  DARK = 'Dark',
  DRAMATIC = 'Dramatic',
  FUNKY = 'Funky',
  HAPPY = 'Happy',
  INSPIRATIONAL = 'Inspirational',
  ROMANTIC = 'Romantic',
  SAD = 'Sad',
}

// Queries

const searchMusic = (networkId: string, params: any) => {
  const searchParams: any = {
    limit: params.limit,
    offset: params.offset,
    genre: params.genre ? params.genre : null,
    mood: params.mood ? params.mood : null,
  }
  return AdoriService.searchMusic(networkId, searchParams)
}

const getLibMusic = (networkId: string, params: any) => {
  return AdoriService.fetchLibMusic(networkId, params)
}
const uploadMusic = (networkId: string, data: any) => {
  return AdoriService.uploadMusic(networkId, data)
}

// Hooks
export const useGetSearchMusic = (params: any, { enabled }: any) => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  return useQuery([musicQueryKeys.SEARCH_MUSIC, networkId, params], () => searchMusic(networkId, params), {
    keepPreviousData: true,
    ...queryDefaults,
    staleTime: Infinity,
    cacheTime: Infinity,
    enabled,
  })
}
export const useGetLibMusic = (params: any, { enabled }: any) => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  return useQuery([musicQueryKeys.LIB_MUSIC, networkId, params], () => getLibMusic(networkId, params), {
    keepPreviousData: true,
    ...queryDefaults,
    cacheTime: Infinity,
    staleTime: Infinity,
    enabled,
  })
}

// mutations
export const useUploadMusic = () => {
  const networkId = store.getters.networkId || localStorage.getItem('networkId')
  return useMutation((data: any) => uploadMusic(networkId, data))
}
