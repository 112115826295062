var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "flex flex-column ba b--adori-gray br3 pa2 bg-adori-light-gray w-49 darker-hover pointer",
      on: { click: _vm.selectMusic },
    },
    [
      _c("div", { staticClass: "flex white items-center" }, [
        _c(
          "i",
          {
            staticClass: "material-icons light-gray f3 v-mid pa1",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.togglePlay.apply(null, arguments)
              },
            },
          },
          [_vm._v(_vm._s(_vm.playButtonIcon))]
        ),
        _c(
          "div",
          { staticClass: "truncate", staticStyle: { width: "200px" } },
          [_vm._v(_vm._s(_vm.music.title))]
        ),
      ]),
      _c("div", { staticClass: "flex items-center silver" }, [
        _c("i", { staticClass: "material-icons f6 v-mid pa1" }, [
          _vm._v("timer"),
        ]),
        _c("div", { staticClass: "mh1" }, [
          _vm._v(_vm._s(_vm.durationInSeconds)),
        ]),
        _vm._v("\n    ·\n    "),
        _c("div", { staticClass: "mh1" }, [
          _vm._v(_vm._s(_vm.music.genre) + ", " + _vm._s(_vm.music.mood)),
        ]),
      ]),
      _c(
        "audio",
        {
          ref: "audio",
          staticClass: "v-mid ml4",
          attrs: { preload: "auto" },
          on: { pause: _vm.handlePause },
        },
        [_c("source", { attrs: { src: _vm.audioSrc } })]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }