






















import { Vue, Component, Prop } from 'vue-property-decorator'
import VoiceSelection from '@/components/Common/VoiceSelection.vue'
import randomText from '@/utils/loadingTexts'

@Component({
  components: {
    VoiceSelection,
  },
})
export default class ModalCommonLoader extends Vue {
  @Prop(String) loaderText!: string

  randomLoadingText = 'Do not run! We are your friends!'

  created() {
    this.randomText()
  }

  randomText() {
    setTimeout(() => {
      this.randomLoadingText = randomText()
      this.randomText()
    }, 7000)
  }
}
