var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "TheMainPage",
    [
      _c("TheHeader"),
      _c(
        "div",
        { staticClass: "flex flex-wrap" },
        [
          _c("TheSideNavigation"),
          _c(
            "div",
            { staticClass: "limiter-no-padding scrollbar" },
            [
              _c("TheTitle", [
                _c(
                  "div",
                  {
                    staticClass: "absolute topShift",
                    attrs: { slot: "left" },
                    slot: "left",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "flex items-center pointer dim",
                        on: { click: _vm.goBack },
                      },
                      [
                        _c(
                          "i",
                          {
                            staticClass:
                              "material-icons f3 light-gray mr2 v-btm",
                          },
                          [_vm._v("arrow_back")]
                        ),
                        _c("span", { staticClass: "light-gray fw1" }, [
                          _vm._v("Back"),
                        ]),
                      ]
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "flex",
                    attrs: { slot: "right" },
                    slot: "right",
                  },
                  [
                    _vm.parsedBlogScenes.length
                      ? _c(
                          "div",
                          { staticClass: "flex items-center" },
                          [
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.isUnsavedChanges,
                                    expression: "isUnsavedChanges",
                                  },
                                ],
                                staticClass: "silver mr2",
                              },
                              [
                                _vm._v("unsaved "),
                                _c(
                                  "i",
                                  {
                                    staticClass:
                                      "material-icons light-gray f4 v-mid",
                                  },
                                  [_vm._v("cached")]
                                ),
                              ]
                            ),
                            _c("BaseButtonBorder", {
                              staticClass: "mr2 ba",
                              attrs: {
                                text: "Save",
                                loading: _vm.isUpdateDraftLoading,
                                disabled: _vm.isUpdateDraftLoading,
                                onClick: _vm.saveDraft,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.parsedBlogScenes.length
                      ? _c("BaseButtonRed", {
                          attrs: {
                            text: _vm.btnCreateText,
                            onClick: _vm.selectVoice,
                            disabled: _vm.exportLimitExceeded,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                [
                  _vm.isParseFetching ||
                  _vm.isDraftFetching ||
                  _vm.isSaveDraftLoading
                    ? _c("div", { staticClass: "w-100 flex mt-3" }, [
                        _c("div", {
                          staticClass:
                            "w-30 bb bw1 b--adori-gray bg-adori-light-gray flex justify-center items-center mr3 mb3 br2 skeleton custom-size-category",
                        }),
                        _c("div", {
                          staticClass:
                            "w-70 bb bw1 b--adori-gray bg-adori-light-gray flex justify-center items-center mb3 br2 skeleton custom-size",
                        }),
                      ])
                    : _vm.parsingMethod == "URL" && !_vm.parsedBlogScenes.length
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "flex flex-column mt-3 justify-center items-center silver pa4 tc bg-adori-light-gray ba b--adori-gray br2 br-bottom",
                        },
                        [
                          _c("div", { staticClass: "flex" }, [
                            _c(
                              "i",
                              {
                                staticClass:
                                  "material-icons white f2 v-mid mb1",
                              },
                              [_vm._v(" article ")]
                            ),
                            _c(
                              "i",
                              {
                                staticClass:
                                  "material-icons white f2 v-mid mb1",
                              },
                              [_vm._v(" trending_flat ")]
                            ),
                            _c(
                              "i",
                              {
                                staticClass:
                                  "material-icons white f2 v-mid mb1",
                              },
                              [_vm._v(" smart_display ")]
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "light-gray ph3 pv2 f4 db b-color" },
                            [
                              _vm._v(
                                "Convert your Blog post into an Engaging Video"
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "w-40" }, [
                            _vm._v(
                              "Insert blog link and get AI-generated scenes with relevant images"
                            ),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass:
                                "w-40 flex mt4 items-center v-mid justify-center",
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.blogUrlInp,
                                    expression: "blogUrlInp",
                                  },
                                ],
                                staticClass:
                                  "w-60 db bw0 bg-adori-gray br2 ph3 pv2 gray f6 white mr2",
                                attrs: {
                                  type: "text",
                                  placeholder: "Paste blog URL",
                                },
                                domProps: { value: _vm.blogUrlInp },
                                on: {
                                  keypress: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.handleBlogParse.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.blogUrlInp = $event.target.value
                                  },
                                },
                              }),
                              _c("BaseButtonRed", {
                                staticClass: "pv2",
                                attrs: {
                                  text: "Create Video",
                                  onClick: _vm.handleBlogParse,
                                  loading: _vm.isParseFetching,
                                  disabled:
                                    !_vm.blogUrlInp || _vm.isParseFetching,
                                },
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "divider" }),
                          _c(
                            "div",
                            {
                              staticClass:
                                "light-gray ph3 pv2 f4 db b-color mt4",
                            },
                            [_vm._v("Supported blog sites")]
                          ),
                          _c(
                            "div",
                            { staticClass: "web pt2" },
                            [
                              _vm._l(_vm.blogListData, function (item, i) {
                                return _c(
                                  "span",
                                  {
                                    key: i,
                                    staticClass: "chip",
                                    on: {
                                      click: function ($event) {
                                        return _vm.openBlog(item.value)
                                      },
                                    },
                                  },
                                  [
                                    _c("span", { staticClass: "chip-text" }, [
                                      _vm._v(_vm._s(item.title)),
                                    ]),
                                  ]
                                )
                              }),
                              _c("span", { staticClass: "more" }, [
                                _vm._v("and many more..."),
                              ]),
                            ],
                            2
                          ),
                          _c("div", { staticClass: "light-gray mt4 pt2 f4" }, [
                            _vm._v("Is your blog not supported?"),
                          ]),
                          _c("BaseButtonRed", {
                            staticClass: "pv1 mt2",
                            attrs: {
                              text: "Contact Us",
                              onClick: _vm.openChat,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm.parsingMethod == "FILE" &&
                      !_vm.parsedBlogScenes.length
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "flex flex-column mt-3 justify-center items-center silver pa4 tc bg-adori-light-gray ba b--adori-gray br2 br-bottom",
                        },
                        [
                          _c("div", { staticClass: "flex" }, [
                            _c(
                              "i",
                              {
                                staticClass:
                                  "material-icons white f2 v-mid mb1",
                              },
                              [_vm._v(" text_snippet ")]
                            ),
                            _c(
                              "i",
                              {
                                staticClass:
                                  "material-icons white f2 v-mid mb1",
                              },
                              [_vm._v(" trending_flat ")]
                            ),
                            _c(
                              "i",
                              {
                                staticClass:
                                  "material-icons white f2 v-mid mb1",
                              },
                              [_vm._v(" smart_display ")]
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "light-gray ph3 pv2 f4 db b-color" },
                            [_vm._v("Convert pdf into an Engaging Video")]
                          ),
                          _c("div", { staticClass: "w-40" }, [
                            _vm._v(
                              "Upload pdf or text file and get AI-generated scenes with relevant images"
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "mt4 mb5" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "w-100 br2 flex flex-column items-center justify-center b--light-gray b--dashed pv5 ph4 pointer",
                                  class:
                                    _vm.isUploadingAudio || _vm.dropped
                                      ? "not-allowed o-20"
                                      : _vm.bgDragColor
                                      ? "dark-fill"
                                      : "link",
                                  on: {
                                    dragover: [
                                      function ($event) {
                                        $event.preventDefault()
                                      },
                                      function ($event) {
                                        _vm.bgDragColor = true
                                      },
                                    ],
                                    dragleave: function ($event) {
                                      _vm.bgDragColor = false
                                    },
                                    drop: function ($event) {
                                      $event.preventDefault()
                                      return _vm.dropFile($event)
                                    },
                                    click: _vm.handleUploadFile,
                                  },
                                },
                                [
                                  _c(
                                    "i",
                                    {
                                      staticClass:
                                        "material-icons f1 silver mb2",
                                    },
                                    [_vm._v("upload_file")]
                                  ),
                                  _c("div", { staticClass: "f4 silver" }, [
                                    _vm._v(
                                      "Click to select .pdf, .txt file or drag it here"
                                    ),
                                  ]),
                                  _vm.selectedTextFile
                                    ? _c("span", { staticClass: "mt2 gray" }, [
                                        _vm._v(_vm._s(_vm.selectedFileName)),
                                      ])
                                    : _vm._e(),
                                  _vm.selectedTextFile
                                    ? _c("span", { staticClass: "f6 gray" }, [
                                        _vm._v(
                                          "(" +
                                            _vm._s(_vm.selectedFileSize) +
                                            ")"
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                              _c("BaseButtonRed", {
                                staticClass: "scn-btn ph4",
                                attrs: {
                                  text: _vm.isParseFetching
                                    ? "Converting"
                                    : "Convert",
                                  onClick: _vm.handleFileParse,
                                  loading: _vm.isParseFetching,
                                  disabled:
                                    !_vm.selectedTextFile ||
                                    _vm.isParseFetching,
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm.parsingMethod == "IDEA" &&
                      !_vm.parsedBlogScenes.length
                    ? _c("div", { staticClass: "mt-3" }, [
                        _c("div", { staticClass: "w-100 flex" }, [
                          _c("div", { staticClass: "w-50" }, [
                            _c(
                              "div",
                              { staticClass: "pb gray f5 flex items-center" },
                              [_vm._v("Enter your idea query here")]
                            ),
                            _c("div", { staticClass: "relative" }, [
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.topic,
                                    expression: "topic",
                                  },
                                ],
                                staticClass:
                                  "db bw0 bg-adori-light-gray br2 ph3 pv3 gray f6 white w-95 mt3",
                                attrs: {
                                  rows: "3",
                                  type: "text",
                                  placeholder:
                                    "Craft a concise 30-second video voiceover script about [topic].",
                                },
                                domProps: { value: _vm.topic },
                                on: {
                                  keypress: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.sendQuery.apply(null, arguments)
                                  },
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.topic = $event.target.value
                                  },
                                },
                              }),
                              _c(
                                "div",
                                { staticClass: "absolute top-0 right-2" },
                                [
                                  _c(
                                    "i",
                                    {
                                      staticClass:
                                        "material-icons light-gray f3 v-mid mt3 pointer",
                                      on: { click: _vm.openTopicRecommend },
                                    },
                                    [_vm._v("arrow_drop_down")]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "absolute top-0 right-0" },
                                [
                                  _vm.isOpenAiFetching
                                    ? _c("div", {
                                        staticClass: "loader loader-inline mt3",
                                      })
                                    : _c(
                                        "i",
                                        {
                                          staticClass:
                                            "material-icons light-gray f4 v-mid mt3 pointer",
                                          on: { click: _vm.sendQuery },
                                        },
                                        [_vm._v("send")]
                                      ),
                                ]
                              ),
                            ]),
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.openAiRes,
                                  expression: "openAiRes",
                                },
                              ],
                              staticClass:
                                "db bw0 bg-adori-light-gray br2 ph3 pv3 gray f6 white w-95 mt3 scrollbar mb2",
                              staticStyle: { height: "55vh" },
                              attrs: {
                                type: "text",
                                placeholder:
                                  "Query response will be displayed here.",
                              },
                              domProps: { value: _vm.openAiRes },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.openAiRes = $event.target.value
                                },
                              },
                            }),
                            _c(
                              "div",
                              { staticClass: "flex justify-between w-95" },
                              [
                                _c("BaseButtonBorder", {
                                  attrs: {
                                    text: "Discard",
                                    onClick: function () {
                                      return (_vm.openAiRes = "")
                                    },
                                    disabled:
                                      _vm.openAiRes &&
                                      _vm.openAiRes.length === 0,
                                  },
                                }),
                                _c("BaseButtonBorder", {
                                  attrs: {
                                    text: "Move >>",
                                    onClick: _vm.moveToFinal,
                                    disabled:
                                      _vm.openAiRes &&
                                      _vm.openAiRes.length === 0,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "w-50 ml3" }, [
                            _c("div", { staticClass: "flex justify-between" }, [
                              _c(
                                "div",
                                { staticClass: "pb gray f5 flex items-center" },
                                [_vm._v("Final Draft")]
                              ),
                              _c(
                                "div",
                                { staticClass: "pb gray f5 flex items-center" },
                                [
                                  _vm._v(
                                    "Estimated Duration: " +
                                      _vm._s(_vm.formattedIdeaDuration)
                                  ),
                                ]
                              ),
                            ]),
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.finalDraft,
                                  expression: "finalDraft",
                                },
                              ],
                              staticClass:
                                "db bw0 bg-adori-light-gray br2 ph3 pv3 gray f6 white w-100 mt3 scrollbar mb2",
                              staticStyle: { height: "67vh" },
                              attrs: {
                                type: "text",
                                placeholder: "Final draft, edit as required",
                              },
                              domProps: { value: _vm.finalDraft },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.finalDraft = $event.target.value
                                },
                              },
                            }),
                            _c(
                              "div",
                              { staticClass: "flex justify-between" },
                              [
                                _c("BaseButtonBorder", {
                                  attrs: {
                                    text: "Discard",
                                    onClick: _vm.discardFinalScript,
                                    disabled: _vm.finalDraft.length === 0,
                                  },
                                }),
                                _c("div", { staticClass: "flex" }, [
                                  _c(
                                    "div",
                                    { staticClass: "flex items-center" },
                                    [
                                      _c("BaseButtonRed", {
                                        staticClass: "mr2",
                                        class: {
                                          "b-red": _vm.finalDraft.length,
                                        },
                                        attrs: {
                                          text: "Create Scenes",
                                          onClick: _vm.handleScriptParse,
                                          disabled: _vm.finalDraft.length === 0,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ])
                    : _vm.parsingMethod == "TEXT" &&
                      !_vm.isParseFetching &&
                      !_vm.parsedBlogScenes.length
                    ? _c("div", [
                        _c(
                          "div",
                          { staticClass: "mt-2" },
                          [
                            _c("TextEditor", {
                              on: { "parse-text": _vm.handleTextParse },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm.parsingMethod == "MONDEE" &&
                      !_vm.isParseFetching &&
                      !_vm.parsedBlogScenes.length
                    ? _c("div", [
                        _c("div", { staticClass: "mt5" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex justify-between items-center w-50 mb2",
                            },
                            [
                              _c("div", { staticClass: "white" }, [
                                _vm._v("paste the json object"),
                              ]),
                              _c("BaseButtonRed", {
                                attrs: {
                                  text: "Create Scenes",
                                  onClick: _vm.handleJsonParse,
                                },
                              }),
                            ],
                            1
                          ),
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.jsonText,
                                expression: "jsonText",
                              },
                            ],
                            staticClass: "bg-adori-gray w-50 pa3 br3 white",
                            staticStyle: { "min-height": "30rem" },
                            domProps: { value: _vm.jsonText },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.jsonText = $event.target.value
                              },
                            },
                          }),
                        ]),
                      ])
                    : _vm.parsedBlogScenes.length
                    ? _c("div", { staticClass: "mt2" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex mt1 justify-between items-center silver pa3 tc bg-adori-light-gray ba b--adori-gray br2 br-bottom relative",
                          },
                          [
                            _c("div"),
                            _c(
                              "div",
                              {
                                staticClass: "absolute f7 pointer o-50",
                                staticStyle: { left: "1rem" },
                                on: { click: _vm.generateReport },
                              },
                              [_vm._v("Send Report")]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "absolute pointer flex justify-center items-center",
                                staticStyle: { left: "6rem" },
                                on: { click: _vm.undo },
                              },
                              [
                                _c(
                                  "i",
                                  {
                                    staticClass: "material-icons f3 pointer",
                                    class:
                                      _vm.blogHistory.length &&
                                      _vm.currentHistoryIndex > 1 &&
                                      "white",
                                  },
                                  [_vm._v("undo")]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "absolute pointer flex justify-center items-center",
                                staticStyle: { left: "8rem" },
                                on: { click: _vm.redo },
                              },
                              [
                                _c(
                                  "i",
                                  {
                                    staticClass: "material-icons f3 pointer",
                                    class:
                                      _vm.blogHistory.length &&
                                      _vm.currentHistoryIndex <
                                        _vm.blogHistory.length - 1 &&
                                      "white",
                                  },
                                  [_vm._v("redo")]
                                ),
                              ]
                            ),
                            _c("div", [
                              _c("p", { staticClass: "ml5 pl5" }, [
                                _vm._v(
                                  "Estimated Video Duration: " +
                                    _vm._s(_vm.formattedDuration)
                                ),
                              ]),
                              _vm.exportLimitExceeded
                                ? _c("span", { staticClass: "red ml5 pl5" }, [
                                    _vm._v(
                                      "You have exceeded the maximum video duration limit of " +
                                        _vm._s(_vm.exportLimit) +
                                        " minutes"
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                            !_vm.isParseFetching
                              ? _c("BaseButtonRed", {
                                  staticClass: "auto-pick",
                                  attrs: {
                                    id: "blog_scenes_tour_step_9",
                                    text: "Auto-Pick Missing Scene Images",
                                    icon: "auto_awesome",
                                    onClick: _vm.automateSceneImages,
                                    loading: _vm.isSceneImgFetching,
                                    disabled:
                                      _vm.isSceneImgFetching ||
                                      _vm.exportLimitExceeded,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "flex mt2" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "w-30 mr1 bg-adori-light-gray white pa3 mr3 relative",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "absolute right-0" },
                                [
                                  _c(
                                    "BaseNewTooltip",
                                    { attrs: { text: "Show tour of product" } },
                                    [
                                      _c(
                                        "i",
                                        {
                                          staticClass:
                                            "mt3 material-icons f3 mr2 v-btm pointer",
                                          on: {
                                            click: function ($event) {
                                              return _vm.showTour(true)
                                            },
                                          },
                                        },
                                        [_vm._v("info")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c("h3", [_vm._v("Video Setting")]),
                              _c(
                                "div",
                                { staticClass: "flex items-center mt3 mb2" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "pt1 white f6 flex items-center",
                                    },
                                    [_vm._v("Thumbnail")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex justify-end items-center mh2",
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "pointer ai-icon",
                                        attrs: {
                                          src: require("@/assets/ai.svg"),
                                        },
                                        on: { click: _vm.toggleThumbnailPanel },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                              _c("div", { staticClass: "pointer" }, [
                                _c("img", {
                                  attrs: {
                                    src: _vm.thumbnailImage,
                                    alt: "",
                                    width: "250",
                                  },
                                  on: { click: _vm.toggleThumbnailPanel },
                                }),
                              ]),
                              _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "flex items-center mt3 mb2" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "pt1 white f6 flex items-center",
                                      },
                                      [_vm._v("Title")]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex justify-end items-center mh2",
                                        attrs: {
                                          id: "blog_scenes_tour_step_1",
                                        },
                                      },
                                      [
                                        _c("img", {
                                          staticClass: "pointer ai-icon",
                                          attrs: {
                                            src: require("@/assets/ai.svg"),
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.openImagePanel(
                                                "AI_TITLE"
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                                _c("input", {
                                  staticClass:
                                    "db bn bg-adori-gray b--gray br2 ph3 pv3 gray f6 white w-100 scrollbar mb2 mt1",
                                  attrs: {
                                    rows: "3",
                                    type: "text",
                                    placeholder: "enter the title",
                                  },
                                  domProps: { value: _vm.title },
                                  on: {
                                    input: function ($event) {
                                      return _vm.updateTitle(
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                              _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "flex items-center mt3 mb2" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "pt1 white f6 flex items-center",
                                      },
                                      [_vm._v("Description")]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex justify-end items-center mh2",
                                        attrs: {
                                          id: "blog_scenes_tour_step_2",
                                        },
                                      },
                                      [
                                        _c("img", {
                                          staticClass: "pointer ai-icon",
                                          attrs: {
                                            src: require("@/assets/ai.svg"),
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.openImagePanel(
                                                "AI_DESCRIPTION"
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                                _c("textarea", {
                                  staticClass:
                                    "db bn bg-adori-gray b--gray br2 ph3 pv3 gray f6 white w-100 scrollbar mb2 mt1",
                                  attrs: {
                                    rows: "3",
                                    type: "text",
                                    placeholder: "",
                                  },
                                  domProps: { value: _vm.description },
                                  on: {
                                    input: function ($event) {
                                      return _vm.updateDescription(
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                              _c(
                                "div",
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "flex items-center mt3 mb2",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "pt1 white f6 flex items-center",
                                        },
                                        [_vm._v("Keywords")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex justify-end items-center mh2",
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "pointer ai-icon",
                                            attrs: {
                                              src: require("@/assets/ai.svg"),
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.openImagePanel(
                                                  "AI_KEYWORDS"
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c("input", {
                                    staticClass:
                                      "db bn bg-adori-gray b--gray br2 ph3 pv3 gray f6 white w-100 scrollbar mb2 mt1",
                                    attrs: {
                                      rows: "3",
                                      type: "text",
                                      placeholder:
                                        "enter keywords separated by comma",
                                    },
                                    domProps: { value: _vm.keywords },
                                    on: {
                                      input: function ($event) {
                                        return _vm.updateKeywords(
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _vm.blogUrlInp == "https://www.example.com" &&
                                  _vm.btnClicked
                                    ? _c("BaseButtonRed", {
                                        staticClass: "btn2 mt2",
                                        attrs: {
                                          text: "Example Video Preview",
                                          icon: "visibility",
                                          onClick: function () {
                                            _vm.showSampleBlogPreview =
                                              !_vm.showSampleBlogPreview
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "flex items-center mt3 mb2" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "pt1 white f6 flex items-center",
                                      },
                                      [
                                        _c(
                                          "i",
                                          {
                                            staticClass:
                                              "f6 material-icons white v-top mr1",
                                          },
                                          [_vm._v("music_note")]
                                        ),
                                        _vm._v(
                                          " Background Music\n                  "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass: "chip hide-child relative",
                                    on: { click: _vm.toggleBackgroundMusic },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "chip-text truncate",
                                        staticStyle: { "max-width": "200px" },
                                      },
                                      [_vm._v(_vm._s(_vm.selectedMusic))]
                                    ),
                                    !!_vm.musicData
                                      ? _c(
                                          "i",
                                          {
                                            staticClass:
                                              "material-icons adori-gray v-mid pointer f3 ml3 grow absolute custom-minus-pos child",
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.removeMusic.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                    cancel\n                  "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "w-100" },
                            _vm._l(_vm.blogSceneList, function (scene, key) {
                              return _c("SceneBlock", {
                                key: key,
                                attrs: { scene: scene, text: scene.text },
                                on: {
                                  updateScene: _vm.updateScene,
                                  addScene: _vm.addScene,
                                  removeScene: _vm.removeScene,
                                  removeSceneImage: _vm.removeSceneImage,
                                  aiPanel: _vm.toggleAIPanel,
                                  blogImages: _vm.toggleBlogImages,
                                },
                              })
                            }),
                            1
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.isModalLoader
                    ? _c("ModalLoader", {
                        attrs: {
                          isLoaderActive: _vm.isConverting,
                          loaderText: _vm.btnCreateText,
                          totalTagsCount: _vm.totalTags.length,
                          tagCount: _vm.currentTags.length,
                        },
                        on: {
                          closeModal: function ($event) {
                            _vm.isModalLoader = !_vm.isModalLoader
                          },
                          "voice-details": _vm.handleVoiceDetails,
                        },
                      })
                    : _vm._e(),
                  _vm.isSceneImgFetching
                    ? _c("ModalCommonLoader", {
                        attrs: { loaderText: "Auto picking images" },
                      })
                    : _vm._e(),
                  _vm.isModalDemoLoader
                    ? _c("ModalDemoLoader", {
                        on: {
                          closeModal: function ($event) {
                            _vm.isModalDemoLoader = !_vm.isModalDemoLoader
                          },
                        },
                      })
                    : _vm._e(),
                  _c("AIPanel", {
                    ref: "_searchAndSelectTray",
                    attrs: {
                      data: _vm.aiData,
                      onSelectItem: _vm.selectItem,
                      context: _vm.selectedContext,
                      scene: _vm.selectedScene,
                    },
                    on: { viewImages: _vm.viewImages },
                  }),
                  _vm.showRateLimitPopup
                    ? _c("BaseRateLimitPopup", {
                        attrs: { secondsLeft: _vm.rateLimitSecondsLeft },
                        on: {
                          closeModal: function ($event) {
                            _vm.showRateLimitPopup = false
                          },
                        },
                      })
                    : _vm._e(),
                  _vm.showSampleBlogPreview
                    ? _c("BaseSampleBlogPreview", {
                        on: {
                          closeModal: function ($event) {
                            _vm.showSampleBlogPreview = false
                          },
                        },
                      })
                    : _vm._e(),
                  _vm.showImagePreview
                    ? _c("ModalPreviewImages", {
                        attrs: { image: _vm.image },
                        on: { close: _vm.previewClose },
                      })
                    : _vm._e(),
                  _vm.showContentModal
                    ? _c("ModalContent", {
                        attrs: {
                          context: _vm.selectedContext,
                          data: _vm.aiData,
                          scene: _vm.selectedScene,
                          isSowVideo: _vm.isSowVideo,
                        },
                        on: {
                          closeModal: function ($event) {
                            _vm.showContentModal = false
                          },
                          "load-image": _vm.handleContentLoad,
                          "load-video": _vm.handleContentVideoLoad,
                        },
                      })
                    : _vm._e(),
                  _vm.showMusicModal
                    ? _c("ModalMusic", {
                        on: {
                          closeModal: function ($event) {
                            _vm.showMusicModal = false
                          },
                          selectMusic: _vm.handleSelectMusic,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }