















import { Component, Vue } from 'vue-property-decorator'
import {
  Doc,
  Text,
  Paragraph,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Link,
  Image,
  Blockquote,
  ListItem,
  BulletList,
  OrderedList,
  TodoItem,
  TodoList,
  TextAlign,
  Indent,
  HorizontalRule,
  HardBreak,
  History,
  Fullscreen,
  LineHeight,
} from 'element-tiptap'
import AdoriService from '@/services/adori'
import { Getter } from 'vuex-class'

@Component({
  components: {
    Doc,
    Text,
    Paragraph,
    Heading,
    Bold,
    Italic,
    Strike,
    Underline,
    Link,
    Image,
    Blockquote,
    ListItem,
    BulletList,
    OrderedList,
    TodoItem,
    TodoList,
    TextAlign,
    Indent,
    HorizontalRule,
    HardBreak,
    History,
    Fullscreen,
    LineHeight,
  },
})
export default class TextEditor extends Vue {
  @Getter networkId!: any
  extensions = [
    new Doc(),
    new Text(),
    new Paragraph(),
    new Heading({ level: 5 }),
    new Bold({ bubble: true }),
    new Underline({ bubble: true }),
    new Italic({ bubble: true }),
    new Strike({ bubble: true }),
    new Link({ bubble: true }),
    new Image({
      uploadRequest: this.uploadRequest,
    }),
    new Blockquote(),
    new TextAlign(),
    new ListItem(),
    new BulletList({ bubble: true }),
    new OrderedList({ bubble: true }),
    new TodoItem(),
    new TodoList(),
    new Indent(),
    new HardBreak(),
    new HorizontalRule({ bubble: true }),
    new Fullscreen(),
    new LineHeight(),
    new History(),
  ]
  async uploadRequest(file: File) {
    const res: any = await AdoriService.uploadImage(this.networkId, file)
    return res.originalUrl
  }

  content = `<h2 style="text-align: center;">Welcome To Adori Text to Video 🚀
</h2>
<p style="text-align: center;">This is an
  <strong>easy-to-use</strong> text editor. Copy paste text with images from any website or start writing your own script.
</p>
<p style="text-align: center;">
  <img src="https://cdn.images.adorilabs.com/static/copy-paste.png" alt="" title="" width="200" data-display="inline">
</p>
<p style="text-align: center;">After you're done editing, click on the
  <strong>Create Scene</strong> button in the top right corner to generate AI-generated scenes with relevant images.
</p>
<p>
</p>
<p>
</p>
<p style="text-align: center;">
  <strong>Do you have any questions or need help?</strong>
</p>
<p style="text-align: center;">Click on the chat icon in the bottom right corner to send us a message, and we'll get back to you.
</p>`

  output = {
    json: {},
    html: this.content,
  }

  handleTextParse() {
    this.$emit('parse-text', this.output.html)
  }

  onUpdate(output: any, options: any) {
    const { getJSON, getHTML } = options
    this.output.json = getJSON()
    this.output.html = getHTML()
  }
}
