






















import { secondsToMmss } from '@/utils/time'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class MusicCard extends Vue {
  @Prop() music!: any
  durationInSeconds = '00:00'
  isPlaying = false

  get audioSrc() {
    return this.music.url
  }

  currentAudio!: any

  get playButtonIcon() {
    return this.isPlaying ? 'stop_circle' : 'volume_up'
  }

  handlePause() {
    this.isPlaying = false
  }

  togglePlay() {
    if (this.currentAudio.paused) {
      this.isPlaying = true
      const audioElements = document.querySelectorAll('audio')
      audioElements.forEach((audio) => {
        audio.pause()
      })
      this.currentAudio.play()
    } else {
      this.isPlaying = false
      this.currentAudio.pause()
    }
  }

  selectMusic() {
    this.$emit('selectMusic', this.music)
  }

  mounted() {
    this.currentAudio = this.$refs.audio
    this.currentAudio.addEventListener('loadedmetadata', () => {
      // Duration is now available
      this.durationInSeconds = secondsToMmss(Math.ceil(this.currentAudio.duration))
    })
  }

  destroyed() {
    this.currentAudio.pause()
    this.isPlaying = false
  }
}
