var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      attrs: { hasCustomEvent: "", hideCloseButton: true },
      on: {
        closeModal: function () {
          _vm.$emit("closeModal")
        },
      },
    },
    [
      _c("template", { slot: "body" }, [
        _c("div", { staticClass: "w-100 flex flex-column items-center" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: true,
                  expression: "true",
                },
              ],
              staticClass: "flex w-100 justify-center items-center",
            },
            [_c("div", { staticClass: "img-loader" })]
          ),
          _c(
            "span",
            {
              staticClass: "text-display mt4",
              attrs: { "data-content": _vm.loaderText },
            },
            [_vm._v(_vm._s(_vm.loaderText))]
          ),
          _c(
            "span",
            {
              staticClass: "silver mt3 tc f6",
              staticStyle: { "max-width": "350px" },
            },
            [_vm._v(_vm._s(_vm.randomLoadingText))]
          ),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }